<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 d-block d-xl-none d-md-none d-lg-none">
        <img
          style="width: 100%"
          src="https://www.vakilgiri.com/wp-content/uploads/2022/08/Vakilgiri-About-Us.webp"
          alt=""
        />
      </div>
      <div
        class="col-xl-7 col-lg-7 col-md-7 col col-12 font pt-5"
        style="color: #000; font-size: 12pt; padding-left: 5%"
      >
        <h3 class="text-center">
          <strong style="color: #000">Welcome To</strong>
        </h3>
        <h2 class="text-center" style="color: #f21000; font-size: 24pt">
          <strong>VAKILGIRI LEGTECH</strong>
        </h2>
        <h3 class="text-center">
          <strong style="color: #000">(Be Vocal for LawCAL)</strong>
        </h3>

        <!-- <strong> -->
        <p class="font">
          Vakilgiri is not just an Online platform for one stop solution of all your legal
          and tax compliances issues, whether it is for corporate or for personal level
          but it is an idea which will bring revolution in the Legal &amp; Tax Compliance
          industry of not only just in India but in the world too.
        </p>
        <p class="font">
          We at Vakilgiri LegTech have noticed that in this fast-moving world, where
          technology is changing every second and opening new innovative ways to earn
          money every single day. From IT to manufacturing industry, from farming to
          hospitality industry, every industry is adopting technology to become more and
          more efficient and effective except for one industry, i.e., India’s Legal &amp;
          Tax Compliance Industry, this industry which is the back bone of every other
          industry is still following its age-old techniques with minimum or no
          technological advancements.
        </p>
        <!-- </strong> -->

        <div class="row mt-3 text-center">
          <div class="col-12 d-block d-xl-none d-md-none d-lg-none">
            <a
              href="http://www.vakilgiri.com/about-us/"
              class="btn text-white"
              style="background-color: #000; box-shadow: 0px 0px 8px gray; width: 40%"
            >
              <strong> Read More</strong>
            </a>
            &nbsp;
            <button
              class="btn text-white"
              style="background-color: #f21000; box-shadow: 0px 0px 8px gray; width: 50%"
            >
              <strong> Partner Login</strong>
            </button>
          </div>

          <div class="col-12 d-none d-xl-block d-md-block d-lg-block">
            <a
              href="http://www.vakilgiri.com/about-us/"
              class="btn text-white"
              style="background-color: #000; box-shadow: 0px 0px 8px gray; width: 30%"
            >
              <strong> Know More</strong>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.vakilgiri.com/get-api/"
              class="btn text-white"
              style="background-color: #f21000; box-shadow: 0px 0px 8px gray; width: 30%"
            >
              <strong> Partner Login</strong>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 d-none d-xl-block d-md-block d-lg-block">
        <img
          style="width: 100%"
          src="https://www.vakilgiri.com/wp-content/uploads/2022/08/Vakilgiri-About-Us.webp"
          alt=""
        />
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 d-block d-xl-none d-md-none d-lg-none">
        <img
          style="width: 100%"
          src="https://www.vakilgiri.com/wp-content/uploads/2022/08/Vakilgiri-Get-API-768x740.webp"
          alt=""
        />
      </div>
      <div
        class="col-xl-7 col-lg-7 col-md-7 col col-12 font pt-5"
        style="color: #000; font-size: 12pt; padding-left: 5%"
      >
        <p>
          Why to <strong>GO ALONE</strong>? when we can <strong>GO ALONG</strong>. Yes, my
          friend, get the <strong>APIs</strong> of the <strong>vakilgiri.com</strong> for
          <strong>YOUR WEBSITE/APP</strong> and become the part of the
          <strong>BIGGEST DIGITAL REVOLUTION IN INDIA</strong>.
        </p>

        <h1 class="font">
          <strong>GET API</strong>
        </h1>
        <hr style="width: 100px; height: 5px; background-color: #f21000" />
        <div class="elementor-widget-container">
          Do you have <strong>A WEBSITE</strong>/AN<strong>
            APP ON GOOGLE PLAY STORE</strong
          >
          and <strong>UNABLE TO ATTRACT CUSTOMERS/ DOWNLOADS?</strong>

          Do you want to not only
          <strong>ATTRACT MORE VIEWS/DOWNLOADS</strong> but to <strong>EARN </strong>more
          than you currently are <strong>WITHOUT DOING ANYTHING </strong>from the same
          <strong>WEBSITE/APP</strong>?

          <strong>IF YES</strong>, then my friend this is for <strong>YOU</strong>.
        </div>

        <div class="row mt-3">
          <div class="col-12 d-block d-xl-none d-md-none d-lg-none">
            <a
              href="http://www.vakilgiri.com/about-us/"
              class="btn text-white"
              style="background-color: #000; box-shadow: 0px 0px 8px gray; width: 40%"
            >
              <strong> Know More</strong>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.vakilgiri.com/get-api/"
              class="btn text-white"
              style="background-color: #f21000; box-shadow: 0px 0px 8px gray; width: 40%"
            >
              <strong> Get API</strong>
            </a>
          </div>

          <div class="col-12 d-none d-xl-block d-md-block d-lg-block">
            <a
              href="http://www.vakilgiri.com/about-us/"
              class="btn text-white"
              style="background-color: #000; box-shadow: 0px 0px 8px gray; width: 30%"
            >
              <strong> Know More</strong>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.vakilgiri.com/get-api/"
              class="btn text-white"
              style="background-color: #f21000; box-shadow: 0px 0px 8px gray; width: 30%"
            >
              <strong> Get API</strong>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 d-none d-xl-block d-md-block d-lg-block">
        <img
          style="width: 100%"
          src="https://www.vakilgiri.com/wp-content/uploads/2022/08/Vakilgiri-Get-API-768x740.webp"
          alt=""
        />
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-xl-5 col-lg-5 col-md-5 col-12" style="padding-left: 5%">
        <img
          style="width: 95%"
          src="https://www.vakilgiri.com/wp-content/uploads/2023/01/3372214.webp"
          alt=""
        />
      </div>
      <div class="col-xl-7 col-lg-7 col-md-7 col-12 pt-5 font">
        <div style="color: #000; font-size: 12pt; padding-right: 5%">
          <p>
            Are you a <strong>CA/CS</strong> or a <strong>LAWYER</strong> and want to have
            <strong>NATIONWIDE CLIENTELE WITHOUT GOING ANYWHERE</strong>?
            <strong>YES, YOU HEARD IT RIGHT</strong>, now take your
            <strong>PRACTICE AT NEW HEIGHTS </strong>with
            <strong>vakilgiri.com.</strong>
          </p>
        </div>

        <h1 class="font">
          <strong> Join as Professional</strong>
        </h1>
        <hr style="width: 150px; height: 5px; background-color: #f21000" />
        <div style="color: #000; font-size: 12pt">
          <p>
            Why <strong>WASTE</strong> your<strong> TALENT AND KNOWLEDGE</strong>
            in<strong> SOME SMALL TOWN</strong> when the
            <strong>WHOLE WORLD IS WAITING FOR YOU. FULFILL YOUR DREAMS</strong>
            by getting your <strong>OWN</strong> <strong>CUSTOMIZED WEBSITE</strong> from
            <strong>VAKILGIRI</strong> and become a
            <strong>SUCCESSFUL PROFESSIONAL</strong> with
            <strong>NATIONWIDE REACH</strong>.
          </p>
        </div>

        <div class="row mt-3">
          <div class="col-12 d-block d-xl-none d-md-none d-lg-none">
            <button
              class="btn text-white"
              style="background-color: #000; box-shadow: 0px 0px 8px gray; width: 40%"
            >
              <strong> Know More</strong>
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <button
              class="btn text-white"
              style="background-color: #f21000; box-shadow: 0px 0px 8px gray; width: 40%"
            >
              <strong> Get API</strong>
            </button>
          </div>

          <div class="col-12 d-none d-xl-block d-md-block d-lg-block">
            <button
              class="btn text-white p-1"
              style="background-color: #000; box-shadow: 0px 0px 8px gray; width: 30%"
            >
              <strong> Know More</strong>
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <button
              class="btn text-white p-1"
              style="background-color: #f21000; box-shadow: 0px 0px 8px gray; width: 50%"
            >
              <strong> Become Vakilgiri Professional</strong>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-xl-7 col-lg-7 col-md-7 col-12 pt-5" style="padding-left: 5%">
        <h1 class="font"><strong>Get Franchise</strong></h1>
        <hr style="width: 100px; height: 5px; background-color: #f21000" />

        <div style="color: #000; font-size: 12pt">
          Are you <strong>TIRED</strong> with your
          <strong>CONVENTIONAL BUSINESSES/ MONOTONOUS JOBS</strong>? Aspiring for some
          <strong>MILLION-DOLLAR INNOVATIVE BUSINESS IDEA</strong>? Want to have your
          <strong>OWN BRAND/ START-UP</strong> in the market? Then, my friend, this is
          <strong>FOR YOU</strong>.
        </div>
        <div style="color: #000; font-size: 12pt">
          <p>
            Join the <strong>Vakilgiri</strong> to start your
            <strong>OWN CONSULTANCY</strong> with
            <strong>ACCESS NOT LIMITED TO YOUR LOCAL AREA BUT AT ALL OVER INDIA</strong>
            with <strong>IMMENSE EARNING OPPORTUNITIES</strong>.
          </p>
        </div>

        <div class="row mt-3">
          <div class="col-12 d-block d-xl-none d-md-none d-lg-none">
            <a
              href="http://www.vakilgiri.com/about-us/"
              class="btn text-white"
              style="background-color: #000; box-shadow: 0px 0px 8px gray; width: 48%"
            >
              <strong> Know More</strong>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.vakilgiri.com/get-api/"
              class="btn text-white"
              style="background-color: #f21000; box-shadow: 0px 0px 8px gray; width: 40%"
            >
              <strong> Get API</strong>
            </a>
          </div>

          <div class="col-12 d-none d-xl-block d-md-block d-lg-block">
            <a
              href="http://www.vakilgiri.com/about-us/"
              class="btn text-white p-1"
              style="background-color: #000; box-shadow: 0px 0px 8px gray; width: 30%"
            >
              <strong> Know More</strong>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.vakilgiri.com/get-api/"
              class="btn text-white p-1"
              style="background-color: #f21000; box-shadow: 0px 0px 8px gray; width: 30%"
            >
              <strong>Get Franchise</strong>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 col-12 pt-5" style="padding-left: 5%">
        <img
          style="width: 100%"
          src="https://www.vakilgiri.com/wp-content/uploads/2023/01/vakilgiri-franchisee.webp"
          alt=""
        />
      </div>
    </div>

    <div class="row pt-3 pb-3" style="padding-left: 1%; padding-right: 1%">
      <div class="col-12 text-center">
        <h1><strong>SERVICES</strong></h1>
        <!-- <hr style="width: 100px; height: 5px; background-color: #f21000" /> -->
      </div>
      <div class="col-md-4 col-sm- col-xs-12 col-xl-4" style="padding: 5px 5px 5px 5px">
        <div class="card" style="height: 100%; border-radius: 18px">
          <div class="card-body">
            <img
              class="border-row"
              src="https://apk.mudhrape.com/wp-content/uploads/2022/01/fab2.png"
              style="width: 25px"
              alt=""
            />
            <b class="text" style="font-size: 12pt; color: #00364f">&nbsp;&nbsp;BASIC</b>

            <div
              class="row border-row"
              style="
                border-radius: 10px;
                background-color: #f2f2f2;
                line-height: 12px;
                margin-top: 5px;
                margin-bottom: 10px;
                padding: 10px 10px 10px 10px;
              "
            >
              <b style="color: #f21300"
                ><small
                  >Earn upto Rs.15000 per month and enjoy higher comission.</small
                ></b
              >
            </div>

            <div class="row border-row">
              <div class="col-md-12 col-sm-12 col-xl-12">
                <ul class="navbar-nav mr-auto" style="color: #00364f">
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">PAN Application</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">TAN Application</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Properitorship Registration</b
                        >
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">GST Registration</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">FSSAI Registration</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">ISO - Non IAF</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">ISO - IAF</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">CSR Registration</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >NGO Darpan Registration</b
                        >
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Trademark Registration</b
                        >
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Udyam Registration</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">DSC Registration</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Labour Law Registration</b
                        >
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Logo Designing</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">ITR Filing</b>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm- col-xs-12 col-xl-4" style="padding: 5px 5px 5px 5px">
        <div class="card" style="height: 100%; border-radius: 18px">
          <div class="card-body">
            <img
              class="border-row"
              src="https://apk.mudhrape.com/wp-content/uploads/2022/01/fab2.png"
              style="width: 25px"
              alt=""
            />
            <b class="text" style="font-size: 12pt; color: #00364f">&nbsp;&nbsp;PRO</b>

            <div
              class="row border-row"
              style="
                border-radius: 10px;
                background-color: #f2f2f2;
                line-height: 12px;
                margin-top: 5px;
                margin-bottom: 10px;
                padding: 10px 10px 10px 10px;
              "
            >
              <b style="color: #f21300"
                ><small
                  >Earn upto Rs.15000 per month and enjoy higher comission.</small
                ></b
              >
            </div>

            <div class="row border-row">
              <div class="col-md-12 col-sm-12 col-xl-12">
                <ul class="navbar-nav mr-auto" style="color: #00364f">
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">GST Return Filing</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">GST Return Filing </b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">GST Amendements</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">FSSAI State Licence</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">FSSAI Cancellation</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">FSSAI Return Filing</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Partnership Registration</b
                        >
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">LLP Registration</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">OPC Registration</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Private Ltd Registration</b
                        >
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Trust Registration</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Society Registration</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Section-8 Registration</b
                        >
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">12AB Registration</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">80G Registration</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Trademark Objection</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Startup India Registration</b
                        >
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Website Development</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">TDS Return</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">TCS Return</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">DIR-3 KYC</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">INC-20A</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Auditor Appointment</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Auditor Resignation</b>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm- col-xs-12 col-xl-4" style="padding: 5px 5px 5px 5px">
        <div class="card" style="height: 100%; border-radius: 18px">
          <div class="card-body">
            <img
              class="border-row"
              src="https://apk.mudhrape.com/wp-content/uploads/2022/01/fab2.png"
              style="width: 25px"
              alt=""
            />
            <b class="text" style="font-size: 12pt; color: #00364f"
              >&nbsp;&nbsp;ADVANCE</b
            >

            <div
              class="row border-row"
              style="
                border-radius: 10px;
                background-color: #f2f2f2;
                line-height: 12px;
                margin-top: 5px;
                margin-bottom: 10px;
                padding: 10px 10px 10px 10px;
              "
            >
              <b style="color: #f21300"
                ><small
                  >Earn upto Rs.15000 per month and enjoy higher comission.</small
                ></b
              >
            </div>

            <div class="row border-row">
              <div class="col-md-12 col-sm-12 col-xl-12">
                <ul class="navbar-nav mr-auto" style="color: #00364f">
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">GST Cancellation</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >FSSAI Central Licence
                        </b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">FSSAI Renewal</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Nidhi Company Registration</b
                        >
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Micro Finance Registration</b
                        >
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Producer Company Registration</b
                        >
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Public Ltd Registrationn</b
                        >
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Trademark Renewal</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Company Address Change</b
                        >
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Share Holding Change</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Add Director</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Remove Director</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Increase in Capital</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Closing Company</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Trademark Renewal</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">LLP Object Change</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">LLP Address Change</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Add Partners in LLP</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Remove Partners from LLP</b
                        >
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Closing LLP</b>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Micro Finance Software</b
                        >
                      </div>
                    </div>
                  </li>
                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Trust Compliance</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Society Compliance</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Partnership Compliance</b
                        >
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">LLP Compliances</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">OPC Compliance</b>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Private Ltd Compliance</b
                        >
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Nidhi Company Compliance</b
                        >
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Micro Finance Compliance</b
                        >
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px"
                          >Producer Company Compliance</b
                        >
                      </div>
                    </div>
                  </li>

                  <li class="nav-item" style="margin-top: 2px">
                    <div class="d-flex">
                      <div>
                        <font-awesome-icon
                          icon="check"
                          class="bg-danger text-white"
                          style="padding: 2px; border-radius: 4px"
                        />
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <b class="text" style="margin-left: 0px">Public Ltd Compliance</b>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="why-choose">
    <div class="row pb-5" style="padding-left: 5%; padding-right: 5%">
      <div class="col-12 pt-4 text-center font pb-1">
        <h2 class="why-choose-h4"><strong>Why Choose Us?</strong></h2>
        <strong class="why-choose-strong"
          >Best Online Company Registration in India</strong
        >
      </div>
      <div
        class="col-xl-1 col-lg-1 col-md-1 d-none d-xl-block d-lg-block d-md-block"
        style="padding: 5px 5px 5px 5px"
      ></div>
      <div
        class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12"
        style="padding: 5px 5px 5px 5px"
      >
        <div
          class="card why-cards"
          style="border: 1px solid #f21000; border-radius: 15px; height: 100%"
        >
          <div class="card-body text-center">
            <h1 style="color: #f21000">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
            </h1>
            <strong class="why-choose-strong" style="color: #f21000"
              >7 Working Days</strong
            >

            <p style="text-align: justify; color: #000; padding-top: 10px">
              <small>
                We provide the fastest Online Company Registration Services in India. Our
                team knows the importance and thrill of getting your first company
                registration. Thus, delivers services with utmost seriousness.
              </small>
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12"
        style="padding: 5px 5px 5px 5px"
      >
        <div
          class="card why-cards"
          style="border: 1px solid #f21000; border-radius: 15px; height: 100%"
        >
          <div class="card-body text-center">
            <h1 style="color: #f21000">
              <i aria-hidden="true" class="fas fa-gavel"></i>
            </h1>
            <strong class="why-choose-strong" style="color: #f21000"
              >Personal Assistant</strong
            >

            <p style="text-align: justify; color: #000; padding-top: 10px">
              <small>
                Once, you decide to get started with your company registration process
                with us, we assign you a personal dedicated assistant who ensures a smooth
                & efficient user experience and answers all your queries on time.
              </small>
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12"
        style="padding: 5px 5px 5px 5px"
      >
        <div
          class="card why-cards"
          style="border: 1px solid #f21000; border-radius: 15px; height: 100%"
        >
          <div class="card-body text-center">
            <h1 style="color: #f21000">
              <i aria-hidden="true" class="fa fa-rupee-sign"></i>
            </h1>
            <strong class="why-choose-strong" style="color: #f21000"
              >Lowest Price In India</strong
            >

            <p style="text-align: justify; color: #000; padding-top: 10px">
              <small>
                “First step is always that toughest”; we have redefined this phrase and
                thus help you take your first step towards entrepreneurship with ease. You
                can rely on Vakilgiri- Best CA Consultants in India as we are committed to
                serve you at the lowest possible cost.
              </small>
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12"
        style="padding: 5px 5px 5px 5px"
      >
        <div
          class="card why-cards"
          style="border: 1px solid #f21000; border-radius: 15px; height: 100%"
        >
          <div class="card-body text-center">
            <h1 style="color: #f21000">
              <i class="fa fa-headphones" aria-hidden="true"></i>
            </h1>
            <strong class="why-choose-strong" style="color: #f21000"
              >Best Customer Support</strong
            >

            <p style="text-align: justify; color: #000; padding-top: 10px">
              <small>
                We are very committed to serve you with the best of our capabilities.
                Reach out to us even at odd hours. We will gladly resolve your queries.
                That’s why we have a whopping “5-star rating” on Google.&nbsp;<br />You
                can reach out to us via call, SMS, email, Whatsapp anytime, anyday.
              </small>
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12"
        style="padding: 5px 5px 5px 5px"
      >
        <div
          class="card why-cards"
          style="border: 1px solid #f21000; border-radius: 15px; height: 100%"
        >
          <div class="card-body text-center">
            <h1 style="color: #f21000">
              <i aria-hidden="true" class="fas fa-download"></i>
            </h1>
            <strong class="why-choose-strong" style="color: #f21000"
              >Easy Uploads & Downloads</strong
            >

            <p style="text-align: justify; color: #000; padding-top: 10px">
              <small>
                Since, the incorporation is 100% online, we have developed a CRM through
                which you can upload and download the required e-forms and documents and
                can also track the process your online company incorporation. Our system
                is 100% transparent. Thus, we don’t have anything to hide.
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Form from "vform";

export default {
  data: () => ({
    infoData: "",
    userData: {},
    form: new Form({
      username: "",
      password: "",
    }),
  }),

  methods: {
    async login() {
      this.$axios
        .post("retailer/sign-in", {
          password: this.form.password,
          email: this.form.username,
        })
        .then((res) => {
          console.log(res.data);
          this.userLoginData = res.data.data.user;
          localStorage.setItem("userData", JSON.stringify(this.userLoginData));
          localStorage.setItem("accessToken", res.data.data.token);
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push({ name: "Home" });
        })
        .catch((error) => {
          console.log(error.response);
          this.form.errors.errors = error.response.data.message;
        });
    },
  },
  created() {
    if (localStorage.getItem("accessToken")) {
      this.infoData = localStorage.getItem("accessToken");
      this.userData = JSON.parse(localStorage.getItem("userData"));
      console.log(this.userData);



      
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
/* .container {
  font-family: "Poppins", sans-serif;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
} */

@media only screen and (min-width: 390px) {
  h1 {
    font-size: 34pt;
    text-transform: uppercase;
    color: black;
    text-shadow: 0px 0px 1px black;
  }
  /* h2 {
    font-size: 5pt;
  } */
}
/* for mobile */
@media only screen and (max-width: 390px) {
  h1 {
    text-transform: uppercase;
    font-size: 22pt;
    color: black;
    text-shadow: 0px 0px 1px black;
  }
  /* h2 {
    font-size: 5pt;
  } */
}
.why-choose {
  background-color: #f210002f;
}
.font {
  font-family: "Poppins", sans-serif;
}
.why-choose-strong {
  color: black;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  text-shadow: 0px 0px 1px black;
}
.why-choose-h4 {
  font-family: "Poppins", sans-serif;
  font-size: 26pt;
  font-weight: 900;
  color: #f21000;
  text-transform: uppercase;
  text-shadow: 0px 0px 5px gray;
}
.why-cards:hover {
  box-shadow: 0px 0px 30px gray;
}
/* 
.material-textfield {
  position: relative;
}

label {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: gray;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}
input {
  height: 30px;
  width: 80%;
  font-size: 1rem;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 1rem 0.7rem;
  color: gray;
  transition: 1s ease-out;
}
input:focus {
  border-color: #f21000;
}
input:focus + label {
  color: #f21000;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
input:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
} */

.bg-img-custom {
  background-size: cover;
  height: 500px;
  background-image: url("/app-assets/images/slider-5.png");
}
</style>
